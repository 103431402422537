import React from 'react';
import Layout from '../../components/Layout';
import {withIntl} from '../../i18n';
import Pricing from '../../templates/Pricing/Page';

class Page extends React.PureComponent {
  render() {
    return (
      <Layout page={'pricing'}>
        <Pricing type={'support'} {...this.props}/>
      </Layout>
    );
  }
}

export default withIntl(Page);
